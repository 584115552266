@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.whatsapp-button {
  background-color: #25d366;
  animation: pulse 1.5s infinite;
}

.whatsapp-button:hover {
  background-color: #25d366;
}

.animated-text {
  display: inline-block;
  white-space: pre-wrap;
  overflow: hidden;
}

.animated-text .letter {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: calc(0.05s * var(--i));
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
